<template>
    <div :class="$style.docs">
        <div :class="$style.container">
            <div :class="$style.content3">
                <div :class="$style.contentInner">
                    <div :class="$style.rowParent">
                        <div :class="$style.row">
                            <div :class="$style.rightContent1">
                                <div :class="$style.heading">
                                    ECCR Docs
                                </div>
                                <div :class="$style.supportingText">
                                    <p :class="$style.welcomeToThe">
                                        Welcome to the ECCR! ECCR supports authoring frameworks, importing existing frameworks from supported document types, and editing frameworks of competencies, skills, and other capabilities.
                                    </p>
                                    <p :class="$style.welcomeToThe">
&nbsp;
                                    </p>
                                    <p :class="$style.welcomeToThe">
                                        Navigate to the frameworks page and start creating frameworks right away without following the below steps.
                                    </p>
                                </div>
                            </div>
                            <img
                                :class="$style.mockupIcon"
                                alt=""
                                src="@/components/eccr/assets/Mockup.png">
                        </div>
                        <div :class="$style.container1">
                            <div :class="$style.imageContainer">
                                <img
                                    :class="$style.imageIcon"
                                    alt=""
                                    src="@/components/eccr/assets/Image.png">
                            </div>
                            <div :class="$style.rightContent2">
                                <div :class="$style.headingDesc">
                                    <div :class="$style.heading">
                                        Getting Started
                                    </div>
                                </div>
                                <div :class="$style.features">
                                    <div :class="$style.separator1" />
                                    <div :class="$style.feature">
                                        <div :class="$style.iconShapes">
                                            <img
                                                :class="$style.homeIcon"
                                                alt=""
                                                src="@/components/eccr/assets/clipboard.svg">
                                        </div>
                                        <div :class="$style.content2">
                                            <b :class="$style.title">Create your user and login</b>
                                            <div :class="$style.description">
                                                Navigate to the login screen and select create a new user. Input your details. After creation, login to your account using the username you created. You can now access the user and groups manager as well as create comments on frameworks.
                                            </div>
                                            <div :class="$style.button">
                                                <div :class="$style.text">
                                                    Login
                                                </div>
                                                <img
                                                    :class="$style.chevronRightIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/chevron-right.svg">
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.feature1">
                                        <div :class="$style.iconShapes1">
                                            <img
                                                :class="$style.homeIcon"
                                                alt=""
                                                src="@/components/eccr/assets/calendar-month.svg">
                                        </div>
                                        <div :class="$style.content5">
                                            <b :class="$style.title">Create user groups for collaborating</b>
                                            <div :class="$style.description">
                                                If your team members have also created their account, and you are logged in, you can navigate to the user/groups page and configure a group with defined readers and authors.
                                            </div>
                                            <div :class="$style.button1">
                                                <div :class="$style.text">
                                                    User/groups
                                                </div>
                                                <img
                                                    :class="$style.chevronRightIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/chevron-right.svg">
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.feature">
                                        <div :class="$style.iconShapes2">
                                            <img
                                                :class="$style.homeIcon"
                                                alt=""
                                                src="@/components/eccr/assets/lock.svg">
                                        </div>
                                        <div :class="$style.content2">
                                            <b :class="$style.title">Create a configuration</b>
                                            <div :class="$style.description">
                                                The configuration manager allows you to have full control over the properties you can add to frameworks and competencies. This includes relationship types and resource types. Configure the labels you want to use on editable forms for managing these properties.
                                            </div>
                                            <div :class="$style.button2">
                                                <div :class="$style.text">
                                                    Configuration manager
                                                </div>
                                                <img
                                                    :class="$style.chevronRightIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/chevron-right.svg">
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.feature">
                                        <div :class="$style.iconShapes3">
                                            <img
                                                :class="$style.homeIcon"
                                                alt=""
                                                src="@/components/eccr/assets/lock.svg">
                                        </div>
                                        <div :class="$style.content2">
                                            <b :class="$style.title">Author a framework</b>
                                            <div :class="$style.description">
                                                Navigate to the framework page and create a new framework. Give your framework a memorable name and save it.
                                            </div>
                                            <div :class="$style.button3">
                                                <div :class="$style.text">
                                                    Frameworks
                                                </div>
                                                <img
                                                    :class="$style.chevronRightIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/chevron-right.svg">
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.feature4">
                                        <div :class="$style.iconShapes4">
                                            <img
                                                :class="$style.homeIcon"
                                                alt=""
                                                src="@/components/eccr/assets/lock.svg">
                                        </div>
                                        <div :class="$style.content2">
                                            <b :class="$style.previewTheNew">Collaborate with your team</b>
                                            <div :class="$style.description4">
                                                Members with view access will be able to read and comment on the framework when logged in. Members with admin access will be able to view, edit, manage users, and delete the framework when logged in. Use comments to create dialogues around your authoring process.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="$style.appDocumentationParent">
                            <div :class="$style.appDocumentation">
                                <div :class="$style.ctaCard">
                                    <div :class="$style.heading">
                                        For the Nerds
                                    </div>
                                    <div :class="$style.supportingText1">
                                        If this is your first time using ECCR, or you need a refresher, the following documentation will help explain the different properties you will use as you create and manage your competencies so you can get back to doing the fun nerdy things.
                                    </div>
                                </div>
                                <div :class="$style.frameParent">
                                    <div :class="$style.groupParent">
                                        <div :class="$style.iconShapesParent">
                                            <div :class="$style.iconShapes5">
                                                <img
                                                    :class="$style.paletteIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/palette.svg">
                                            </div>
                                            <b :class="$style.title5">Framework Properties</b>
                                            <div :class="$style.frameWrapper">
                                                <div :class="$style.descriptionParent">
                                                    <div :class="$style.previewTheNew">
                                                        Framework properties are the values that can be added, edited, and deleted for framework objects. Minimal framework properties are id, name, and description.
                                                    </div>
                                                    <div :class="$style.groupWrapper">
                                                        <div :class="$style.frameContainer">
                                                            <div :class="$style.groupContainer">
                                                                <div :class="$style.frameContainer">
                                                                    <div :class="$style.link4">
                                                                        <div :class="$style.text2">
                                                                            Learn more
                                                                        </div>
                                                                        <img
                                                                            :class="$style.barsIcon"
                                                                            alt=""
                                                                            src="@/components/eccr/assets/chevron-right.svg">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div :class="$style.groupFrame">
                                            <div :class="$style.iconShapesGroup">
                                                <div :class="$style.iconShapes5">
                                                    <img
                                                        :class="$style.paletteIcon"
                                                        alt=""
                                                        src="@/components/eccr/assets/palette.svg">
                                                </div>
                                                <b :class="$style.title5">Competency Properties</b>
                                                <div :class="$style.groupDiv">
                                                    <div :class="$style.descriptionParent">
                                                        <div :class="$style.previewTheNew">
                                                            Competency properties are the values that can be added, edited, and deleted for competency objects.
                                                        </div>
                                                        <div :class="$style.groupWrapper">
                                                            <div :class="$style.frameContainer">
                                                                <div :class="$style.groupContainer">
                                                                    <div :class="$style.frameContainer">
                                                                        <div :class="$style.link4">
                                                                            <div :class="$style.text2">
                                                                                Learn more
                                                                            </div>
                                                                            <img
                                                                                :class="$style.barsIcon"
                                                                                alt=""
                                                                                src="@/components/eccr/assets/chevron-right.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :class="$style.frameWrapper2">
                                        <div :class="$style.groupParent1">
                                            <div :class="$style.iconShapesContainer">
                                                <div :class="$style.iconShapes5">
                                                    <img
                                                        :class="$style.paletteIcon"
                                                        alt=""
                                                        src="@/components/eccr/assets/palette.svg">
                                                </div>
                                                <b :class="$style.title5">Configuration</b>
                                                <div :class="$style.frameWrapper3">
                                                    <div :class="$style.descriptionParent">
                                                        <div :class="$style.previewTheNew">
                                                            Configurations control the way frameworks and competencies appear in the editor, as well as what properties, relationships, and in some cases value types of properties and relationships that can be added to frameworks and competencies.
                                                        </div>
                                                        <div :class="$style.groupWrapper">
                                                            <div :class="$style.frameContainer">
                                                                <div :class="$style.groupContainer">
                                                                    <div :class="$style.frameContainer">
                                                                        <div :class="$style.link4">
                                                                            <div :class="$style.text2">
                                                                                Learn more
                                                                            </div>
                                                                            <img
                                                                                :class="$style.barsIcon"
                                                                                alt=""
                                                                                src="@/components/eccr/assets/chevron-right.svg">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.groupWrapper4">
                                                <div :class="$style.iconShapesParent1">
                                                    <div :class="$style.iconShapes5">
                                                        <img
                                                            :class="$style.paletteIcon"
                                                            alt=""
                                                            src="@/components/eccr/assets/palette.svg">
                                                    </div>
                                                    <b :class="$style.title5">User Groups</b>
                                                    <div :class="$style.frameWrapper5">
                                                        <div :class="$style.descriptionParent">
                                                            <div :class="$style.previewTheNew">
                                                                In the ECCR authoring environment, user groups allow for multiple users to assume a single shared 'identity'. Members of a group are granted access to any ECCR object that the group has been explicitly assigned.
                                                            </div>
                                                            <div :class="$style.groupWrapper">
                                                                <div :class="$style.frameContainer">
                                                                    <div :class="$style.groupContainer">
                                                                        <div :class="$style.frameContainer">
                                                                            <div :class="$style.link4">
                                                                                <div :class="$style.text2">
                                                                                    Learn more
                                                                                </div>
                                                                                <img
                                                                                    :class="$style.barsIcon"
                                                                                    alt=""
                                                                                    src="@/components/eccr/assets/chevron-right.svg">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="$style.instanceWrapper">
                                <div :class="$style.frameWrapper7">
                                    <div :class="$style.headingParent">
                                        <div :class="$style.heading3">
                                            <div :class="$style.headingDescription">
                                                <div :class="$style.heading4">
                                                    Import Example Frameworks
                                                </div>
                                                <div :class="$style.dontWaitTo">
                                                    Don’t wait to get started. Try downloading one of our example frameworks to start diving into the ECCR .
                                                </div>
                                            </div>
                                        </div>
                                        <div :class="$style.resources">
                                            <div :class="$style.iconShapesParent2">
                                                <div :class="$style.iconShapes9">
                                                    <img
                                                        :class="$style.paletteIcon"
                                                        alt=""
                                                        src="@/components/eccr/assets/clipboard.svg">
                                                </div>
                                                <div :class="$style.productList">
                                                    <div :class="$style.parent">
                                                        <div :class="$style.div">
                                                            ONET: Basic Skills
                                                        </div>
                                                        <div :class="$style.download">
                                                            Download
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.iconShapesParent2">
                                                <div :class="$style.iconShapes9">
                                                    <img
                                                        :class="$style.paletteIcon"
                                                        alt=""
                                                        src="@/components/eccr/assets/clipboard.svg">
                                                </div>
                                                <div :class="$style.productList">
                                                    <div :class="$style.parent">
                                                        <div :class="$style.div">
                                                            Harvard: Emotional Intelligence
                                                        </div>
                                                        <div :class="$style.download">
                                                            Download
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.iconShapesParent2">
                                                <div :class="$style.iconShapes9">
                                                    <img
                                                        :class="$style.paletteIcon"
                                                        alt=""
                                                        src="@/components/eccr/assets/clipboard.svg">
                                                </div>
                                                <div :class="$style.productList">
                                                    <div :class="$style.parent">
                                                        <div :class="$style.div">
                                                            ACT: Collaborative Problem Solving
                                                        </div>
                                                        <div :class="$style.download">
                                                            Download
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div :class="$style.frameWrapper8">
                                <div :class="$style.productList">
                                    <div :class="$style.faq">
                                        <div :class="$style.headingDesc1">
                                            <div :class="$style.heading">
                                                Other Resources
                                            </div>
                                        </div>
                                        <div :class="$style.row1">
                                            <div :class="$style.item">
                                                <img
                                                    :class="$style.fingerprintIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/fingerprint.svg">
                                                <div :class="$style.rightContent3">
                                                    <div :class="$style.headingDescription1">
                                                        <b :class="$style.title9">Authoring Report</b>
                                                        <div :class="$style.description4">
                                                            Chat support with our friendly customer service agents at your service.
                                                        </div>
                                                    </div>
                                                    <div :class="$style.cta1">
                                                        <div :class="$style.pages">
                                                            Learn more
                                                        </div>
                                                        <img
                                                            :class="$style.chevronRightIcon"
                                                            alt=""
                                                            src="@/components/eccr/assets/chevron-right.svg">
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.item">
                                                <img
                                                    :class="$style.fingerprintIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/question-mark.svg">
                                                <div :class="$style.rightContent3">
                                                    <div :class="$style.headingDescription1">
                                                        <b :class="$style.title9">CaSS Project Website</b>
                                                        <div :class="$style.description4">
                                                            View FAQs for detailed instructions on specific features.
                                                        </div>
                                                    </div>
                                                    <div :class="$style.cta1">
                                                        <div :class="$style.pages">
                                                            Learn more
                                                        </div>
                                                        <img
                                                            :class="$style.chevronRightIcon"
                                                            alt=""
                                                            src="@/components/eccr/assets/chevron-right.svg">
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.item">
                                                <img
                                                    :class="$style.fingerprintIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/newspapper.svg">
                                                <div :class="$style.rightContent3">
                                                    <div :class="$style.headingDescription1">
                                                        <b :class="$style.title9">ECCR Developer Guide</b>
                                                        <div :class="$style.description4">
                                                            Stay up to date with the latest stories and commentary.
                                                        </div>
                                                    </div>
                                                    <div :class="$style.cta1">
                                                        <div :class="$style.pages">
                                                            Learn more
                                                        </div>
                                                        <img
                                                            :class="$style.chevronRightIcon"
                                                            alt=""
                                                            src="@/components/eccr/assets/chevron-right.svg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div :class="$style.row1">
                                            <div :class="$style.item">
                                                <img
                                                    :class="$style.fingerprintIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/fingerprint.svg">
                                                <div :class="$style.rightContent3">
                                                    <div :class="$style.headingDescription1">
                                                        <b :class="$style.title9">ECCR Official Documentation</b>
                                                        <div :class="$style.description4">
                                                            Chat support with our friendly customer service agents at your service.
                                                        </div>
                                                    </div>
                                                    <div :class="$style.cta1">
                                                        <div :class="$style.pages">
                                                            Chat now
                                                        </div>
                                                        <img
                                                            :class="$style.chevronRightIcon"
                                                            alt=""
                                                            src="@/components/eccr/assets/chevron-right.svg">
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.item">
                                                <img
                                                    :class="$style.fingerprintIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/question-mark.svg">
                                                <div :class="$style.rightContent3">
                                                    <div :class="$style.headingDescription1">
                                                        <b :class="$style.title9">ECCR Github</b>
                                                        <div :class="$style.description4">
                                                            View FAQs for detailed instructions on specific features.
                                                        </div>
                                                    </div>
                                                    <div :class="$style.cta1">
                                                        <div :class="$style.pages">
                                                            Learn more
                                                        </div>
                                                        <img
                                                            :class="$style.chevronRightIcon"
                                                            alt=""
                                                            src="@/components/eccr/assets/chevron-right.svg">
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="$style.item">
                                                <img
                                                    :class="$style.fingerprintIcon"
                                                    alt=""
                                                    src="@/components/eccr/assets/newspapper.svg">
                                                <div :class="$style.rightContent3">
                                                    <div :class="$style.headingDescription1">
                                                        <b :class="$style.title9">The ADL Initiative</b>
                                                        <div :class="$style.description4">
                                                            Stay up to date with the latest stories and commentary.
                                                        </div>
                                                    </div>
                                                    <div :class="$style.cta1">
                                                        <div :class="$style.pages">
                                                            Learn more
                                                        </div>
                                                        <img
                                                            :class="$style.chevronRightIcon"
                                                            alt=""
                                                            src="@/components/eccr/assets/chevron-right.svg">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
          <script lang="ts">
                import {defineComponent} from 'vue';
                
                
                export default defineComponent({
                    name: "Docs"
                });
                      </script>
                      
                    <style module>.adlAdvancedDistributedLearnIcon {
                      width: 32px;
                      position: relative;
                      height: 32px;
                      object-fit: cover;
                }
                      .barsIcon {
                            width: 20px;
                            position: relative;
                            height: 20px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .inputText {
                            flex: 1;
                            position: relative;
                            line-height: 125%;
                      }
                      .content {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 10px;
                      }
                      .input {
                            align-self: stretch;
                            border-radius: 8px;
                            background-color: #faf9fb;
                            border: 1px solid #d6d2db;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 12px 16px;
                      }
                      .inputField {
                            width: 402px;
                            background-color: #faf9fb;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .logoSearch {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 22px;
                      }
                      .notificationIcon {
                            border-radius: 16px;
                            background-color: #fff;
                            overflow: hidden;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 4px;
                      }
                      .avatarIcon {
                            width: 32px;
                            border-radius: 16px;
                            height: 32px;
                            object-fit: cover;
                      }
                      .rightContent {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                            padding: 0px 0px 0px 16px;
                            gap: 12px;
                      }
                      .navbar {
                            align-self: stretch;
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0px 16px;
                      }
                      .divider {
                            flex: 1;
                            position: relative;
                            background-color: #e8e6eb;
                            height: 1px;
                      }
                      .separator {
                            align-self: stretch;
                            height: 1px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .applicationUiNavbar {
                            align-self: stretch;
                            background-color: #fff;
                            height: 64px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .logo {
                            width: 232px;
                            height: 36px;
                            display: none;
                      }
                      .homeIcon {
                            width: 16px;
                            position: relative;
                            height: 16px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .pages {
                            position: relative;
                            line-height: 150%;
                            font-weight: 500;
                      }
                      .leftContent {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 12px;
                      }
                      .chevronDownIcon {
                            width: 28px;
                            position: relative;
                            height: 28px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .icon {
                            display: none;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                      }
                      .overview {
                            align-self: stretch;
                            border-radius: 8px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                      }
                      .pages1 {
                            width: 232px;
                            display: none;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                            box-sizing: border-box;
                      }
                      .chevronUpIcon {
                            width: 12px;
                            position: relative;
                            height: 12px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .productList {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .submenu {
                            width: 226px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                            padding: 0px 0px 0px 36px;
                            box-sizing: border-box;
                            gap: 16px;
                      }
                      .multidropdown {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 8px;
                      }
                      .authentication {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                      }
                      .main {
                            align-self: stretch;
                            border-bottom: 1px solid #e5e7eb;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0px 0px 24px;
                            gap: 8px;
                      }
                      .docs1 {
                            align-self: stretch;
                            background-color: #f4f3f6;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 6px 8px;
                      }
                      .text {
                            position: relative;
                            line-height: 16px;
                            font-weight: 500;
                      }
                      .badge {
                            border-radius: 6px;
                            background-color: #d5c6e9;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            padding: 2px 10px;
                      }
                      .xOutlineIcon {
                            width: 10px;
                            position: relative;
                            height: 10px;
                      }
                      .icon4 {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-end;
                      }
                      .header {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                      }
                      .previewTheNew {
                            align-self: stretch;
                            position: relative;
                            line-height: 150%;
                      }
                      .turnNewFeatures {
                            align-self: stretch;
                            position: relative;
                            text-decoration: underline;
                            line-height: 150%;
                            font-family: Inter;
                      }
                      .text1 {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 12px;
                            text-align: left;
                            font-size: 14px;
                            color: #42167a;
                      }
                      .content2 {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 12px;
                      }
                      .cta {
                            align-self: stretch;
                            border-radius: 6px;
                            background-color: #e5dcf4;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 16px;
                            text-align: center;
                            font-size: 12px;
                            color: #2d065d;
                            font-family: Roboto;
                      }
                      .icons {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 20px;
                      }
                      .sidebarBottomIcons {
                            align-self: stretch;
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-end;
                            justify-content: center;
                      }
                      .content1 {
                            align-self: stretch;
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 16px 0px;
                            gap: 24px;
                      }
                      .applicationUiPrimarySidebar {
                            align-self: stretch;
                            width: 256.5px;
                            background-color: #fff;
                            border-right: 1px solid #e8e6eb;
                            box-sizing: border-box;
                            overflow: hidden;
                            flex-shrink: 0;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0px 12px;
                      }
                      .heading {
                            align-self: stretch;
                            position: relative;
                            letter-spacing: -0.01em;
                            line-height: 125%;
                            font-weight: 800;
                      }
                      .welcomeToThe {
                            margin: 0;
                      }
                      .supportingText {
                            align-self: stretch;
                            position: relative;
                            font-size: 18px;
                            line-height: 150%;
                            color: #6b7280;
                      }
                      .rightContent1 {
                            width: 640px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 24px;
                      }
                      .mockupIcon {
                            width: 409px;
                            position: relative;
                            border-radius: 8px;
                            height: 321px;
                            object-fit: cover;
                      }
                      .row {
                            align-self: stretch;
                            background-color: #fff;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 96px 58px;
                            gap: 80px;
                      }
                      .imageIcon {
                            flex: 1;
                            border-radius: 8px;
                            max-width: 100%;
                            overflow: hidden;
                            height: 1122px;
                            object-fit: cover;
                      }
                      .imageContainer {
                            flex: 1;
                            border-radius: 8px;
                            height: 1016px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .headingDesc {
                            width: 519px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .separator1 {
                            align-self: stretch;
                            position: relative;
                            background-color: #e8e6eb;
                            height: 1px;
                      }
                      .iconShapes {
                            width: 32px;
                            border-radius: 9999px;
                            background-color: #d5c6e9;
                            height: 32px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .title {
                            align-self: stretch;
                            position: relative;
                            font-size: 20px;
                            line-height: 150%;
                      }
                      .description {
                            align-self: stretch;
                            position: relative;
                            line-height: 150%;
                            font-family: Roboto;
                            color: #6b7280;
                      }
                      .chevronRightIcon {
                            width: 10px;
                            position: relative;
                            height: 10px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;
                            color: #562990;
                      }
                      .feature {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .iconShapes1 {
                            width: 32px;
                            border-radius: 9999px;
                            background-color: #dceaf5;
                            height: 32px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .button1 {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;
                            color: #135f9b;
                      }
                      .content5 {
                            width: 470px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 12px;
                      }
                      .feature1 {
                            width: 518px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .iconShapes2 {
                            width: 32px;
                            border-radius: 9999px;
                            background-color: #bed8e0;
                            height: 32px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .button2 {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;
                            color: #5c8fa1;
                      }
                      .iconShapes3 {
                            width: 32px;
                            border-radius: 9999px;
                            background-color: #a1b2f8;
                            height: 32px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .button3 {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;
                            color: #4159b2;
                      }
                      .iconShapes4 {
                            width: 32px;
                            border-radius: 9999px;
                            background-color: #cdf5ff;
                            height: 32px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .description4 {
                            align-self: stretch;
                            position: relative;
                            font-size: 16px;
                            line-height: 150%;
                            font-family: Roboto;
                            color: #6b7280;
                      }
                      .feature4 {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                            font-size: 20px;
                      }
                      .features {
                            width: 519px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 32px;
                            font-size: 16px;
                      }
                      .rightContent2 {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 32px;
                      }
                      .container1 {
                            align-self: stretch;
                            background-color: #fff;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 96px 58px;
                            gap: 58px;
                      }
                      .supportingText1 {
                            align-self: stretch;
                            position: relative;
                            font-size: 20px;
                            line-height: 150%;
                            color: #6b7280;
                      }
                      .ctaCard {
                            width: 380px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .paletteIcon {
                            width: 24px;
                            position: relative;
                            height: 24px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .iconShapes5 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            border-radius: 8px;
                            background-color: #d5c6e9;
                            width: 38.8px;
                            height: 48px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .title5 {
                            position: absolute;
                            top: 64px;
                            left: 0px;
                            line-height: 125%;
                            display: inline-block;
                            width: 324.8px;
                      }
                      .text2 {
                            position: relative;
                            line-height: 18px;
                            font-weight: 500;
                      }
                      .link4 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 404px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;
                      }
                      .groupContainer {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 404px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                      }
                      .frameContainer {
                            width: 404px;
                            position: relative;
                            height: 20px;
                      }
                      .groupWrapper {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            font-size: 18px;
                            color: #562990;
                            font-family: Inter;
                      }
                      .descriptionParent {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 326.4px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .frameWrapper {
                            position: absolute;
                            top: 97px;
                            left: 0px;
                            width: 326.4px;
                            height: 132px;
                            font-size: 16px;
                            color: #6b7280;
                            font-family: Roboto;
                      }
                      .iconShapesParent {
                            width: 326.4px;
                            position: relative;
                            height: 229px;
                      }
                      .groupDiv {
                            position: absolute;
                            top: 97px;
                            left: 0px;
                            width: 326.4px;
                            height: 108px;
                            font-size: 16px;
                            color: #6b7280;
                            font-family: Roboto;
                      }
                      .iconShapesGroup {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 326.4px;
                            height: 205px;
                      }
                      .groupFrame {
                            width: 326.4px;
                            position: relative;
                            height: 205px;
                      }
                      .groupParent {
                            width: 668px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 32px;
                      }
                      .frameWrapper3 {
                            position: absolute;
                            top: 97px;
                            left: 0px;
                            width: 326.4px;
                            height: 180px;
                            font-size: 16px;
                            color: #6b7280;
                            font-family: Roboto;
                      }
                      .iconShapesContainer {
                            width: 326.4px;
                            position: relative;
                            height: 277px;
                      }
                      .frameWrapper5 {
                            position: absolute;
                            top: 97px;
                            left: 0px;
                            width: 326.4px;
                            height: 156px;
                            font-size: 16px;
                            color: #6b7280;
                            font-family: Roboto;
                      }
                      .iconShapesParent1 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 326.4px;
                            height: 253px;
                      }
                      .groupWrapper4 {
                            width: 326.4px;
                            position: relative;
                            height: 253px;
                      }
                      .groupParent1 {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 668px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 32px;
                      }
                      .frameWrapper2 {
                            width: 668px;
                            position: relative;
                            height: 277px;
                      }
                      .frameParent {
                            width: 677px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 32px;
                            font-size: 20px;
                      }
                      .appDocumentation {
                            align-self: stretch;
                            background-color: #fff;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 96px 58px;
                            gap: 56px;
                      }
                      .heading4 {
                            width: 672px;
                            position: relative;
                            letter-spacing: -0.01em;
                            line-height: 125%;
                            font-weight: 800;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                      }
                      .dontWaitTo {
                            width: 672px;
                            position: relative;
                            font-size: 20px;
                            line-height: 150%;
                            color: #6b7280;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                      }
                      .headingDescription {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .heading3 {
                            width: 672px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .iconShapes9 {
                            width: 48px;
                            border-radius: 8px;
                            background-color: #d5c6e9;
                            height: 48px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                      }
                      .div {
                            width: 248px;
                            position: relative;
                            line-height: 150%;
                            font-weight: 500;
                            display: inline-block;
                      }
                      .download {
                            align-self: stretch;
                            position: relative;
                            font-size: 14px;
                            line-height: 150%;
                            font-weight: 500;
                            color: #562990;
                      }
                      .parent {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .iconShapesParent2 {
                            width: 296px;
                            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
                            border-radius: 8px;
                            background-color: #fff;
                            height: 199px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 24px;
                            box-sizing: border-box;
                            gap: 16px;
                      }
                      .resources {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 32px;
                            text-align: left;
                            font-size: 20px;
                            font-family: Roboto;
                      }
                      .headingParent {
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 952px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 64px;
                      }
                      .frameWrapper7 {
                            align-self: stretch;
                            position: relative;
                            height: 384px;
                      }
                      .instanceWrapper {
                            width: 952px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: center;
                      }
                      .headingDesc1 {
                            width: 1192px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            font-size: 36px;
                      }
                      .fingerprintIcon {
                            width: 44px;
                            position: relative;
                            height: 44px;
                            overflow: hidden;
                            flex-shrink: 0;
                      }
                      .title9 {
                            align-self: stretch;
                            position: relative;
                            line-height: 125%;
                      }
                      .headingDescription1 {
                            align-self: stretch;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 12px;
                      }
                      .cta1 {
                            align-self: stretch;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 6px;
                            text-align: center;
                            font-size: 14px;
                            color: #562990;
                      }
                      .rightContent3 {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .item {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 16px;
                      }
                      .row1 {
                            width: 1192px;
                            height: 124px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 64px;
                      }
                      .faq {
                            align-self: stretch;
                            background-color: #fff;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 96px 0px;
                            gap: 48px;
                      }
                      .frameWrapper8 {
                            align-self: stretch;
                            background-color: #fff;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            padding: 0px 0px 96px;
                            font-size: 20px;
                      }
                      .appDocumentationParent {
                            align-self: stretch;
                            height: 1222px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 96px;
                      }
                      .rowParent {
                            width: 1307px;
                            height: 2847px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .contentInner {
                            align-self: stretch;
                            width: 1307px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                      }
                      .content3 {
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            font-size: 36px;
                            color: #1b1128;
                      }
                      .container {
                            align-self: stretch;
                            height: 3608px;
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            justify-content: flex-start;
                            font-size: 16px;
                            color: #111928;
                      }
                      .docs {
                            width: 100%;
                            position: relative;
                            background-color: #f4f3f6;
                            overflow: hidden;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            text-align: left;
                            font-size: 14px;
                            color: #6b7280;
                            font-family: Inter;
                      }
                
                </style>